import React from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';


const Contact = () => {
  return (
    <div>
        <section id="contact">
            <div className="container">
            <div className="row mt-5 mb-5">
            <div className="col-lg-6 col-12">
            <h3 className='text-uppercase fw-bold mb-4'>Velocity Media is one of the stellar digital advertising companies. <br/> Let's get in touch.</h3>
              <p>
                <MDBIcon icon="home" className="me-3" />
                XYZ, Gurgaon
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                prafful@velocitymedianetworks.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" />+918105582569
              </p>
    
    
            </div>
            <div className="col-lg-6 col-12">
              <h5>Drop your details, we'll get back to you.</h5>
              <div className="container bg-white">
                <form action="" method='post'>
                  <label for="name">Name</label>
                  <input type="text" id="name" name="name" placeholder="Your name.."/>
              
                  <label for="email">Email</label>
                  <input type="text" id="email" name="email" placeholder="Your email.."/>
              
                  <label for="country">Phone</label>
                  <input type="text" id="phone" name="phone" placeholder="Your phone.."/>    
                  <label for="subject">Subject</label>
                  <input type="text" id="subject" name="subject" placeholder="Subject.."/>    
                  <label for="message">Message</label>
                  <textarea id="message" name="message" placeholder="Write something.."></textarea>
              
                  <input type="submit" value="Submit"/>
                </form>
              </div>
            </div>
          </div>
    
    </div>       
        </section>
    </div>

  );
}

export default Contact;
