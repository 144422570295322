import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import img9 from './img/ads.png';
import img11 from './img/payment-day.png';
import img12 from './img/integration.png';
import img13 from './img/adv1.png';
import img14 from './img/adv2.png';
import img15 from './img/adv3.png';
import img16 from './img/adv4.png';

function LeftTabsExample() {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={6}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link className="w-100 p-5" eventKey="first">
              <h4>For Publishers</h4> <p>Scale your App Monetization and user acquisition needs.</p>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={6}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link className="w-100 p-5" eventKey="second">
              <h4>For Advertisers </h4><p>Tailor made solutions for all your campaign goals. 1500+ app inventory</p>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        </Row>
        <Row>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
            <div className="row mt-5 mb-5">
            <div className="col-lg-3 col-12">
               <div className="card border-0 bg-white rounded bg-transparent">
                  <img src={img9} className="card-img-top img-fluid m-3" id="services_i" alt="..."/>
                  <div className="card-body">
                     <h5 className="card-title">Quality ads</h5>
                     <p className="card-text">Show quality branded ads from big media houses.</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-12 mt-lg-0 mt-3">
               <div className="card border-0 bg-white rounded bg-transparent">
                  <img src={img11} className="card-img-top img-fluid m-3" id="services_i" alt="..."/>
                  <div className="card-body">
                     <h5 className="card-title">Timely Payments</h5>
                     <p className="card-text">Get on time payments on Net 10 basis.</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-12 mt-lg-0 mt-3">
               <div className="card border-0 bg-white rounded bg-transparent">
                  <img src={img12} className="card-img-top img-fluid m-3" id="services_i" alt="..."/>
                  <div className="card-body">
                     <h5 className="card-title">Easy Integrations</h5>
                     <p className="card-text">Just paste our code once and you are done.</p>
                  </div>
               </div>
            </div>
   </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <div className="row mt-5 mb-5">
            <div className="col-lg-3 col-12">
               <div className="card border-0 bg-white rounded bg-transparent">
                  <img src={img13} className="card-img-top img-fluid m-3" id="services_i" alt="..."/>
                  <div className="card-body">
                     <h5 className="card-title">Target</h5>
                     <p className="card-text">Target Visitors from a particular GEO.</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-12 mt-lg-0 mt-3">
               <div className="card border-0 bg-white rounded bg-transparent">
                  <img src={img14} className="card-img-top img-fluid m-3" id="services_i" alt="..."/>
                  <div className="card-body">
                     <h5 className="card-title">Reporting</h5>
                     <p className="card-text">Get reports of the campaign's performance.</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-12 mt-lg-0 mt-3">
               <div className="card border-0 bg-white rounded bg-transparent">
                  <img src={img15} className="card-img-top img-fluid m-3" id="services_i" alt="..."/>
                  <div className="card-body">
                     <h5 className="card-title">Retargeting</h5>
                     <p className="card-text">Retarget your website visitors.</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-12 mt-lg-0 mt-3">
               <div className="card border-0 bg-white rounded bg-transparent">
                  <img src={img16} className="card-img-top img-fluid m-3" id="services_i" alt="..."/>
                  <div className="card-body">
                     <h5 className="card-title">Scale</h5>
                     <p className="card-text">Scale the campaigns to drive higher engagement and maximize the ROI.</p>
                  </div>
               </div>
            </div>
   </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default LeftTabsExample;