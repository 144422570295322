import React from 'react';
import Navtab from './navtab';

import img1 from './img/smm.png';
import img2 from './img/pub.png';
import img3 from './img/adv.png';
import img4 from './img/badge.png';
import img5 from './img/clock.png';
import img6 from './img/international.png';
import img7 from './img/reliability.png';
import img8 from './img/center.png';


const Home = () => {
  return (
    <div>
        <section id="home">

        <div className="row mb-5 px-lg-5 p-3" id="header">
                <div className="col-lg-6 mt-lg-5 col-12">
               <img src={img1} className="img-fluid" alt="..."/>
            </div>
            <div className="col-lg-6 text-white col-12 mt-lg-0 mt-5">
               <div className="iam h1 mt-lg-5">VELOCITY MEDIA NETWORKS</div>
               <span className="text h2"></span>

               <p className="card-text mt-4">Technically driven, statically proven and within 3 years of digital market hold, Velocity Media is one of the stellar digital advertising companies.         </p>
               <p className="card-text">          Brand awareness, Social media eye catcher, marketing solutions for advertisers and optimum Ad revenue generator platform for the existing and potential publishers. Velocity Media has always been the choice and the demand of the publishers for the digital advertising solutions.</p>
   

<button id="btn" className='text-white btn me-2 py-2 px-3'>
    GET IN TOUCH
</button>
            </div>
                </div>


            <div className="container">





                <div className="row mt-5 mb-5">
            <div className="col-lg-6 col-12">
               <div className="row">
                  <div className="col-lg-6 col-12">
                     <div className="card text-center border-0 shadow-lg rounded p-4 bg-transparent">
                        <div>
                           <img src={img4} id='services_i' className="card-img-top img-fluid" alt="..."/>
                        </div>
                        <div className="card-body">
                           <h5>Certified
                           </h5>
                           <p className="card-text"> VMN is a certified digital media agency.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 mt-lg-0 mt-3 col-12">
                     <div className="card text-center border-0 shadow-lg rounded p-4 bg-transparent">
                        <div>
                           <img src={img6} id='services_i' className="card-img-top img-fluid" alt="..."/>
                        </div>
                        <div className="card-body">
                           <h5>Widespread</h5>
                           <p className="card-text">Our audience base is widespread.</p>
                        </div>
                     </div>
                  </div>
                  </div>
                  <div className="row mt-lg-3 mt-0">
                  <div className="col-lg-6 mt-lg-0 mt-3 col-12 ">
                     <div className="card text-center border-0 shadow-lg rounded p-4 bg-transparent">
                       <div>
                        <img src={img5} id='services_i' className="card-img-top img-fluid" alt="..."/>
                       </div>
                        <div className="card-body">
                           <h5>Efficient
                           </h5>
                           <p className="card-text">  We help in generating efficient results.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 mt-lg-0 mt-3 col-12">
                     <div className="card text-center border-0 shadow-lg rounded p-4 bg-transparent">
<div>
   <img src={img7} className="card-img-top img-fluid" id='services_i' alt="..."/>
</div>
                        <div className="card-body">
                           <h5>Reliable
                           </h5>
                           <p className="card-text">You can rely us for new to big campaigns.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5 col-12">
               VELOCITY MEDIA NETWORKS FOR
               <h2>Digital Advertising Solutions</h2>
               Brand awareness, Social media eye catcher, marketing solutions for advertisers and optimum Ad revenue generator platform for the existing and potential publishers. Velocity Media has always been the choice and the demand of the publishers for the digital advertising solutions.
               <div className="row mt-3">
                  <div className="col-lg-6 col-12">
                     <div className="card mb-2 border-0 bg-transparent">
                        <div className="row g-0">
                           <div className="col-1">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-magic" viewBox="0 0 16 16">
                                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                 </svg>
                              </div>
                           </div>
                           <div className="col-11">
                              <div className="">
                                 <h5 className="card-title">&nbsp;Understand Your Audience        </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <div className="card mb-2 border-0 bg-transparent">
                        <div className="row g-0">
                           <div className="col-1">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-magic" viewBox="0 0 16 16">
                                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                 </svg>
                              </div>
                           </div>
                           <div className="col-11">
                              <div className="">
                                 <h5 className="card-title">&nbsp;Engage Your Community
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <div className="card mb-3 border-0 bg-transparent">
                        <div className="row g-0">
                           <div className="col-1">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-magic" viewBox="0 0 16 16">
                                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                 </svg>
                              </div>
                           </div>
                           <div className="col-11">
                              <div className="">
                                 <h5 className="card-title">&nbsp;Engage Your Community
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-12">
                     <div className="card mb-3 border-0 bg-transparent">
                        <div className="row g-0">
                           <div className="col-1">
                              <div className="">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-magic" viewBox="0 0 16 16">
                                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z"/>
                                 </svg>
                              </div>
                           </div>
                           <div className="col-11">
                              <div className="">
                                 <h5 className="card-title">&nbsp;Digital Media Analytics
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         
         <div className="row mt-5 mb-5">
            <h3>
               HAVE A LOOK AT<br/>
               Some Metrices & Numbers
            </h3>
            <p>We’ve been working for years in the market and offering 100% client satisfaction. <br/>
              The results are cost-efficient and durable.</p>
            <div className="col-lg-6 col-12 mt-5">
               <div className="row goto">
                  <div className="col-6">
                     <div className="card text-center border-0 bg-transparent">
                        <div id="" className="last text-danger h1"><span className="count text-danger" data-count="100">100</span>M</div>
                        <div id="clr"></div>
                        <div className="card-body">
                           <h6 className="card-title">DAILY IMPRESSIONS</h6>
                        </div>
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="card text-center border-0 bg-transparent">
                        <div id="" className="last text-danger h1"><span className="count text-danger" data-count="150">150</span>+</div>
                        <div id="clr"></div>
                        <div className="card-body">
                           <h6 className="card-title">CLIENTS</h6>
                        </div>
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="card text-center border-0 bg-transparent">
                        <div id="" className="last text-danger h1"><span className="count text-danger" data-count="500">500</span>+</div>
                        <div id="clr"></div>
                        <div className="card-body">
                           <h6 className="card-title">LIVE CAMPAIGNS</h6>
                        </div>
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="card text-center border-0 bg-transparent">
                        <div id="" className="last text-danger h1"><span className="count text-danger" data-count="314">314</span>k</div>
                        <div id="clr"></div>
                        <div className="card-body">
                           <h6 className="card-title">AUDIENCE BASE</h6>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-lg-6 col-12">
               <img src={img8} className='d-lg-block d-none' alt="" srcset=""/>
            </div>
         </div>






                
         <div className="row mt-5 mb-5">
            <div className="col-lg-6 col-12">
              <div className="cfb card border-0 shadow-lg rounded p-4 " id="main_s">
               <div className="text-center">
                  <img src={img2} id="services_i" className="card-img-top" alt="..."/>
               </div>

                <div className="card-body mt-3">
                  <h5 className="card-title">PUBLISHERS</h5>
                  <p>Our mission is to empower the next wave of heroes and fill the industry with their brave mentality. If you believe you share their traits, then this is our promise: we are here for you. Let’s grow together.</p>
  
  <ul>
    <li>100% FULFILLMENT RATE</li>
    <li>COMPETITIVE RATE </li>
    <li>FRIENDLY AND EASY PAYOUTS</li>
    <li>DEDICATED ACCOUNTS MANAGER</li>
    <li>QUALITY ADS</li>
  </ul>
  
                    
              
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-4">
              <div className="cfb card border-0 shadow-lg rounded p-4 " id="main_s">
   <div className="text-center">
      <img src={img3} id="services_i" className="card-img-top" alt="..."/>
   </div>
                <div className="card-body mt-3">
                  <h5 className="card-title">ADVERTISERS</h5>
                  <p>                  Meet your objectives by reaching the right users with the required modelling – CPC, CPM, CPV, CPUV, video views and CPL with raw leads. With high quality traffic, You will get more impressions, views, clicks and leads.
  
                  </p>
                  <ul>
                    <li> Hitting the BULL’S EYE</li>
                    <li> In-house Advertising                   </li>
                    <li>Traffic                  </li>
                    <li>Reach Your Audience
                    </li>
                    <li>Digital Media Analytics
                    </li>
                  </ul>
                 
  
               
                </div>
              </div>
            </div>
           </div>





         <Navtab/>

































            </div>
        </section>
    </div>
  );
}

export default Home;
