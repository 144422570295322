import React from 'react';
import Slider from './slider';
import img1 from './img/smm.png';
import img2 from './img/revision.png';
import img3 from './img/contact.png';
import img4 from './img/roadmap.png';
import img5 from './img/one.jpg';
import img6 from './img/two.jpg';
import img7 from './img/three.jpg';



const About = () => {
  return (
    <div>
        <section id="about">
        <div className="row mb-5 px-5" id="header-a">

<div className='text-center my-5'>
    
<h5 className='text-danger'>BEST FOR YOU</h5>
<h3 className='text-white'>How VMN Team Can Help</h3>      



</div>  

<div className="col-lg-6">
    <div className="card mb-3">
  <div className="row g-0">
    <div className="col-md-2">
      <img src={img4} className="img-fluid m-3 rounded-start" id="about_i" alt="..."/>
    </div>
    <div className="col-md-10">
      <div className="card-body">
        <h5 className="card-title"> Complete Project Roadmap</h5>
        <p className="card-text">When we finalize any project, we create a project roadmap (timeline), which helps keep work on a proper track, and so you will be able to find out what things are to be done and when.</p>
      </div>
    </div>
  </div>
</div>
    </div>
    <div className="col-lg-6">
    <div className="card mb-3">
  <div className="row g-0">
    <div className="col-md-2">
      <img src={img2} className="img-fluid m-3 rounded-start" id="about_i" alt="..."/>
    </div>
    <div className="col-md-10">
      <div className="card-body">
        <h5 className="card-title"> Unlimited revisions</h5>
        <p className="card-text">Customer satisfaction is our primary concern; that is why we provide unlimited revisions on our designs unless you find them interesting and 100% satisfied.</p>
      </div>
    </div>
  </div>
</div>
    </div>
    <div className="col-12">
    <div className="card mb-3">
  <div className="row g-0">
    <div className="col-md-2">
      <img src={img3} className="img-fluid m-3 rounded-start" id="about_i" alt="..."/>
    </div>
    <div className="col-md-10">
      <div className="card-body">
        <h5 className="card-title"> Professional Work and Service</h5>
        <p className="card-text">Our work is 100% professional, though we’ve got feelings and do things on a personal level. But the final work which you will get will be entirely professional, done by the professionals.</p>
      </div>
    </div>
  </div>
</div>
    </div>


                </div>


            <div className="container">
           
                <div className="row">
                <h3>OUR APPROACH TO DESIGN</h3>
                    <div className="col-lg-6 col-12">
                        
                    <div className="card mb-3 border-0">
  <div className="row g-0">
    <div className="col-md-12">
      <div className="card-body">
        <p className="h6 card-text">
        In our industry, some people stand out.
<br /><br />
You may not recognize them at first glance; they’re defined by their character and their decisions, not their outward appearance. They push new boundaries. 
<br /><br />
They’re not afraid to challenge new markets or take calculated risks. They believe fear is a luxury; they’re too busy taking action.
        </p>
      </div>
    </div>
  </div>
</div>
                    </div>
                    <div className="col-lg-6 col-12">
                    <div className="card mb-3 border-0">
  <div className="row g-0">
    <div className="col-md-12">
      <div className="card-body">
        <p className="card-text h6">
        We believe in these people. We call them digital marketing heroes. We recognize, connect, inspire and support them as they rise – because they bring everyone up with them.
<br /> <br />
Our mission is to empower the next wave of heroes and fill the industry with their brave mentality. If you believe you share their traits, then this is our promise: we are here for you. Let’s grow together.
        </p>
      </div>
    </div>
  </div>
</div>
                    </div>
                </div>



<div className="row mt-5 mb-5">
  <div className="col-6">
<div className="row" style={{height:'100%'}}>
<div className="card border-0" style={{height:'100%'}}>
  <img src={img1} className="card-img-top" style={{height:'100%'}} alt="..."/>
</div>
</div>
  </div>
  <div className="col-6">
    <div className="row">
    <div className="col-6">
  <div className="card border-0">
  <img src={img5} className="card-img-top" alt="..."/>
</div>
  </div>
  <div className="col-6">
  <div className="card border-0">
  <img src={img6} className="card-img-top" alt="..."/>
</div>
  </div>
  <div className="col-6 mt-2">
  <div className="card border-0">
  <img src={img7} className="card-img-top" alt="..."/>
</div>
  </div>
  <div className="col-6 mt-2">
  <div className="card border-0">
  <img src={img1} className="card-img-top" alt="..."/>
</div>
  </div>
    </div>
  </div>
  
</div>












                <div className="row mt-5 mb-5">
                    <h3>Life at Velocity Media</h3>
                    <Slider/>
                </div>





            </div>
        </section>
    </div>
  );
}

export default About;
