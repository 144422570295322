import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './img/logo.png';

function NavScrollExample() {
  return (
    <Navbar sticky="top" className="theme" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/"><img src={logo} id="logo" alt="" srcset="" /></Navbar.Brand>
        <Navbar.Toggle style={{background:'cornflowerblue'}} aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <div className="col-9"></div>
          <Nav className="me-auto col-3 my-2 my-lg-0">
            <Nav.Link className='text-white me-3' href="/">Home</Nav.Link>
            <Nav.Link className='text-white me-3' href="/about">About</Nav.Link>
            <Nav.Link className='text-white' href="/contact">              Contact            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;