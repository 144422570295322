import './App.css';
import Navbartwo from './components/navbartwo';
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import Footer from './components/footer';
import {Route, Routes} from 'react-router';




function App() {
  return (
  <>
  <Navbartwo/>
  <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/about" element={<About/>} />
    <Route path="/contact" element={<Contact/>} />
  </Routes>
  <Footer/>
  </>
  );
}

export default App;
