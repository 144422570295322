import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import Nav from 'react-bootstrap/Nav';
import logo from './img/logo.png';

function Footer() {
  return (
    <MDBFooter className='theme text-center text-lg-start text-white'>


      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='pt-5 text-start'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold'>
                <MDBIcon icon="gem" className="me-3" />
                <img src={logo} id="f_logo" alt=""/>
              </h6>
              <p>
              Brand awareness, Social media eye catcher, marketing solutions for advertisers and optimum Ad revenue generator platform for the existing and potential publishers. Velocity Media has always been the choice and the demand of the publishers for the digital advertising solutions.
              </p>
            </MDBCol>

<hr className='d-lg-none d-block'/>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
              <p>
              <Nav.Link disabled className='text-reset'>
              Brand Awareness
                </Nav.Link>
              </p>
              <p>
              <Nav.Link disabled className='text-reset'>
              Social Media
                </Nav.Link>
              </p>
              <p>
              <Nav.Link disabled className='text-reset'>
              Retargeting
                </Nav.Link>
              </p>
              <p>
              <Nav.Link disabled className='text-reset'>
                  SEO
                </Nav.Link>
              </p>
              <p>
              <Nav.Link disabled className='text-reset'>
              Influencer Marketing
                </Nav.Link>
              </p>
              <p>
              <Nav.Link disabled className='text-reset'>
              Lead Generation
                </Nav.Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4 d-none d-lg-block'>
              <h6 className='text-uppercase fw-bold mb-4'>Links</h6>
              <p>
                <Nav.Link href='/about' className='text-reset'>
                  About
                </Nav.Link>
              </p>
              <p>
                <Nav.Link href='/contact' className='text-reset'>
                  Contact
                </Nav.Link>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                XYZ, Gurgaon
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                prafful@velocitymedianetworks.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" />+918105582569
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2022 Copyright:
        <a className='text-reset fw-bold ms-2' href='/'>
         VMN
        </a>
      </div>
    </MDBFooter>
  );
}

export default Footer;